import "./App.css";
import {
  GeistProvider,
  Themes,
  CssBaseline,
  Page,
  Link,
  Card,
  Grid,
} from "@geist-ui/core";
import { Github, Instagram, Linkedin } from "@geist-ui/icons";

function App() {
  const theme = Themes.createFromDark({ type: "myTheme" });
  return (
    <GeistProvider themes={[theme]} themeType="myTheme">
      <CssBaseline />
      <Page>
        <Page.Content>
          <Grid.Container gap={1}>
            <Grid xs={24} s={12}>
              <Card>
                <Card.Content>
                  <h2>>nicole devillers</h2>
                  <div
                    style={{
                      display: "flex",
                      width: 150,
                      justifyContent: "space-between",
                    }}
                  >
                    <Link href="https://github.com/ndevvy" target="_blank">
                      <Github size={24} />
                    </Link>
                    <Link
                      href="https://www.linkedin.com/in/nicoledevillers/"
                      target="_blank"
                    >
                      <Linkedin size={24} />
                    </Link>
                    <Link
                      href="http://instagram.com/interiorjoke"
                      target="_blank"
                    >
                      <Instagram size={24} />
                    </Link>
                  </div>
                </Card.Content>
              </Card>
            </Grid>
          </Grid.Container>
        </Page.Content>
      </Page>
    </GeistProvider>
  );
}

export default App;
